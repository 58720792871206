import { createSlice } from '@reduxjs/toolkit';
const removeItem = (items, index) => {
  return [...items.slice(0, index), ...items.slice(index + 1)]
}

const slice = createSlice({
  name: 'app',
  initialState: {
    error: null,
    bottomSheetOpen: false,
    selectedH3s: [],
    clustersList: null,
    updating: false,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setBottomSheetOpen: (state, action) => {
      state.bottomSheetOpen = action.payload;
    },
    setSelectedH3s: (state, action) => {
      state.selectedH3s.findIndex(a => a.h3 === action.payload.h3) < 0 ?
      state.selectedH3s.push(action.payload) : state.selectedH3s = removeItem(state.selectedH3s, state.selectedH3s.findIndex(a => a.h3 === action.payload.h3) );
    },
    removeSelectedH3s: (state) => {
      state.selectedH3s =[];
    },
    setClustersList: (state, action) => {
      state.clustersList = action.payload;
    },
    setUpdating: (state, action) => {
      state.updating = action.payload;
    },
  },
});

export default slice.reducer;

export const setError = (payload) => ({ type: 'app/setError', payload });
export const setBottomSheetOpen = (payload) => ({
  type: 'app/setBottomSheetOpen',
  payload,
});
export const setSelectedH3s = (payload) => ({ type: "app/setSelectedH3s", payload });
export const setClustersList = (payload) => ({ type: "app/setClustersList", payload });
export const setUpdating = (payload) => ({ type: "app/setUpdating", payload });
export const removeSelectedH3s = (payload) => ({ type: "app/removeSelectedH3s", payload });