import deepmerge from 'deepmerge';
import { createTheme } from '@material-ui/core';
import { cartoThemeOptions } from '@carto/react-ui';

const customTheme = {};
cartoThemeOptions.palette.primary.main = "#002740";

cartoThemeOptions.palette.primary.light = "#002740";
cartoThemeOptions.palette.secondary.main = "#00A3E0";
cartoThemeOptions.palette.secondary.contrastText = "#ffffff";
cartoThemeOptions.palette.secondary.dark = "#002740";
console.log(cartoThemeOptions.palette.secondary);
const theme = createTheme(deepmerge(cartoThemeOptions, customTheme));

export default theme;

// contrastText: "#2c3032"
// dark: "#8c3800"
// light: "#6be2ad"
// main: "#47db99"
// relatedLight: "rgba(71, 219, 153, 0.08)"


