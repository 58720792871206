(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@reduxjs/toolkit"), require("@deck.gl/core"), require("@carto/react-core"), require("@carto/react-workers"), require("@deck.gl/carto"));
	else if(typeof define === 'function' && define.amd)
		define(["@reduxjs/toolkit", "@deck.gl/core", "@carto/react-core", "@carto/react-workers", "@deck.gl/carto"], factory);
	else if(typeof exports === 'object')
		exports["cartoReactRedux"] = factory(require("@reduxjs/toolkit"), require("@deck.gl/core"), require("@carto/react-core"), require("@carto/react-workers"), require("@deck.gl/carto"));
	else
		root["cartoReactRedux"] = factory(root["@reduxjs/toolkit"], root["@deck.gl/core"], root["@carto/react-core"], root["@carto/react-workers"], root["@deck.gl/carto"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__106__, __WEBPACK_EXTERNAL_MODULE__946__, __WEBPACK_EXTERNAL_MODULE__485__, __WEBPACK_EXTERNAL_MODULE__116__, __WEBPACK_EXTERNAL_MODULE__332__) {
return 